import React from "react"
import { graphql } from "gatsby"
import parse, { htmlparser2 } from "html-react-parser"
import Seo from "components/Seo"

const BlogPostTemplate = ({ data: { previous, next, post } }) => {
  // const featuredImage = {
  //   data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
  //   alt: post.featuredImage?.node?.alt || ``,
  // }

  console.log("POST", post)

  return (
    <>
      <Seo
        title={post.title}
        description={post.excerpt}
        image={
          post?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
            ?.images.fallback.src
        }
      />

      <article className="mb-24" itemScope itemType="http://schema.org/Article">
        <header className="py-16">
          <div className="max-w-screen-lg mx-auto px-4 lg:px-8">
            <div className="pb-0 text-center">
              {post.categories && (
                <ul className="mb-4 inline-flex flex-row flex-wrap -mx-1">
                  {post.categories.nodes.map(category => {
                    return (
                      <li
                        className="px-1 text-gray-500 text-lg"
                        key={category.id}
                      >
                        {category.name}
                      </li>
                    )
                  })}
                </ul>
              )}
              <h1
                itemProp="headline"
                className="text-2xl xl:text-4xl font-medium"
              >
                {parse(post.title, { decodeEntities: true })}
              </h1>
              <div className="mt-4">
                <time className="text-gray-500">{post.date}</time>
              </div>
              {/* {featuredImage?.data && (
                <div className="mt-16">
                  <div className="relative overflow-hidden">
                    <div style={{ paddingBottom: "56.25%" }}></div>
                    <GatsbyImage
                      style={{
                        position: "absolute",
                        height: "100%",
                        width: "100%",
                        inset: 0,
                        objectFit: "cover",
                      }}
                      image={featuredImage.data}
                      alt={featuredImage.alt}
                    />
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </header>

        {!!post.content && (
          <section
            itemProp="articleBody"
            className="max-w-screen-md mx-auto px-4 lg:px-8"
          >
            <div className="prose lg:prose-lg max-w-none">
              {parse(post.content, { decodeEntities: true })}
            </div>
          </section>
        )}
      </article>

      {/* <nav className="container mx-auto px-4 lg:px-8">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.uri} rel="prev">
                ← {parse(previous.title)}
              </Link>
            )}
          </li>

          <li>
            {next && (
              <Link to={next.uri} rel="next">
                {parse(next.title)} →
              </Link>
            )}
          </li>
        </ul>
      </nav> */}
    </>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      categories {
        nodes {
          id
          name
        }
      }
      date(formatString: "yyyy년 MM월 DD일", locale: "ko")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
